/*! Copyright 2009-2015, Texas Instruments Inc. All rights reserved. */

require.config( {
	baseUrl: '/App_Themes/Default/JS',
	paths: {
		localization: 'modules/localization',
		analytics: 'modules/analytics',
		time: 'modules/time',
		knockout: 'lib/knockout-3.4.0',
		'knockout.validation-lib': 'lib/knockout.validation',
		'knockout.validation-custom': 'modules/knockout.validation',
		'knockout.extensions': 'modules/knockout.extensions',
		'knockout.repeat': 'plugins/knockout-repeat',
		'dom-loader': 'modules/dom-loader',
		history: 'modules/jquery.history',
		'json-stable-stringify': 'lib/json-stable-stringify',
		serialization: 'modules/serialization',
		caching: 'modules/caching',
		queuing: 'modules/queuing',
		ajax: 'modules/ajax',
		lightboxes: 'modules/lightboxes',
		rng: 'modules/rng',
		console: 'modules/console',
		recaptcha: 'modules/recaptcha',
		observable: 'modules/observable',
		events: 'modules/events',
		'event-simulator': 'modules/event-simulator',
		'csv-writer': 'modules/csv-writer',
		'utilities/array': 'modules/utilities/array',
		'utilities/binary': 'modules/utilities/binary',
		'utilities/id': 'modules/utilities/id',
		'utilities/escape': 'modules/utilities/escape',
		'utilities/string': 'modules/utilities/string',
		'services/activities': 'modules/services/activities',
		'services/authentication': 'modules/services/authentication',
		'services/localization': 'modules/services/localization',
		'services/downloads': 'modules/services/downloads',
		'services/schoollookupservice': 'modules/services/schoollookupservice',
		'services/crmschoollookupservice': 'modules/services/crmschoollookupservice',
		'services/sharepointformservice': 'modules/services/sharepointformservice',
		'services/tibuildingconcepts': 'modules/services/tibuildingconcepts',
		'services/activitysite': 'modules/services/activitysite',
		SchoolLookUp: 'modules/form/schoollookup',
		CRMSchoolLookUp: 'modules/form/crmschoollookup'
	},
	map: {
		'*': {
			'knockout-extensions': 'knockout.extensions',
			'knockout-repeat': 'knockout.repeat',
			'knockout.validation': 'knockout.validation-custom'
		},
		'knockout.validation-custom': {
			'knockout.validation': 'knockout.validation-lib'
		}
	},
	shim: {
		history: {
			exports: 'History'
		}
	}
} );

( function() {
	define( 'jquery', [], function() {
		return window[ '$' ];
	} );

	function wrapMethod( receiver, method ) {
		return function() {
			return receiver[ method ].apply( receiver, [].slice.call( arguments, 0 ) );
		};
	}

	function wrapMethods( receiver, methods, defaultMethod ) {
		var retval = [].reduce.call( methods, function( obj, method ) {
			obj[ method ] = wrapMethod( receiver, method );
			return obj;
		}, {} );
		if( defaultMethod && {}.hasOwnProperty.call( retval, defaultMethod ) ) retval.default = retval[ defaultMethod ];
		return retval;
	}

	define( 'jquery-deferred', [ 'jquery' ], function( $ ) {
		return wrapMethods( $, [ 'Deferred' ], 'Deferred' );
	} );

	define( 'jquery-extend', [ 'jquery' ], function( $ ) {
		return wrapMethods( $, [ 'extend' ], 'extend' );
	} );

	define( 'jquery-ajax', [ 'jquery' ], function( $ ) {
		var retval = wrapMethods( $, [ 'ajax', 'ajaxPrefilter', 'ajaxSetup', 'get', 'getJSON', 'getScript', 'post', 'load' ], 'ajax' );
		retval.ajaxSettings = $.ajaxSettings;
		return retval;
	} );
}() );
