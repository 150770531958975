(function ($) {
    $(function () {
        const cookieName = "CONSENTMGR",
        cookieExpire = 60,
        reg_match = /\d+$/,        
        $consentPreference = $('#consent_pref_center'),
        $consentPreferenceBox = $('#tiGDPRcpPrefs'),
        $consentBox = $("#tiGDPRecModal");

        function defaultCookieData() {
            //var cookieData={ts:new Date().getTime(),consent:true,c1:1,c2:1,c3:1,c4:1,c5:1,c6:1,c7:1,c8:1,c9:1,c10:1,c11:1,c12:1,c13:1,c14:1,c15:1};        
			var cookieData={ts:new Date().getTime(),consent:true,c1:0,c3:0,c6:0,c7:0};        
			return cookieData;
        }

		function updatedPreferencesValues() {
			var cookieData= getCookieValues();	 
			$consentPreferenceBox.find('.cookie-custom-checkbox').each ( function() {
				var $input = $(this);
				var inputId = $input.attr('id');            
				let match: RegExpExecArray | null;
				match = reg_match.exec(inputId);
				if (match != null) {
					const catId = match[0];
					cookieData["c" + catId] = $input.is(':checked') ? 1 : 0;
				}
			});
			setCookie(cookieData);
		}
		
		function loadPreferencesValues() {	
            var cookieData= getCookieValues();
			$consentPreferenceBox.find('.cookie-custom-checkbox').each ( function() {
				const $input = $(this);
				const inputId = $input.attr('id');
				let match: RegExpExecArray | null;
				if(inputId) {
					match = reg_match.exec(inputId)
					if (match != null) {
						const catId = match[0];
						if(cookieData["c" + catId] !== "1") {
							//nothing
						} else {
							$input.prop('checked', true);
						}
					}          
				}
			});
        }

        function acceptAllPreferences() {
            $consentPreferenceBox.find('.cookie-custom-checkbox').each ( function() {
                const $input = $(this);
                $input.prop('checked', true);
            });
        }

        function getDomainName(hostName) {
            return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1));
        }

        function getCookie(cname) {
            var name = cname + "=";
            var ca = document.cookie.split(";");
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }

        function setCookie(cookieData) {            
            var mo2Val = [];
            for(var i in cookieData) {
                if( cookieData.hasOwnProperty( i ) ) {
                    mo2Val.push( i + ":" + cookieData[i] );
                }
            }
            var expires=new Date();
            expires.setDate(expires.getDate() + cookieExpire);
            var cookieValue = (mo2Val.join("|"));
            var cookieString = "";
            cookieString = cookieName + "=" + encodeURI(cookieValue) + "; path=/; expires=" + expires.toUTCString() + "; domain=" + getDomainName(window.location.hostname);
            document.cookie = cookieString;
        }
        
        function getCookieValues() {
            var values = {};
            if (document.cookie.indexOf(cookieName) === -1) {
				values = defaultCookieData();
			} else {
                try {
                    var cookieData = getCookie(cookieName);
                    if (cookieData) {
                        var i, optOut, optOutData = decodeURI(cookieData).split("|");
                        for (i = 0; i < optOutData.length; i++) {
                            optOut = optOutData[i].split(":");
                            values[optOut[0]] = optOut[1];
                        }
                    }
                } catch (e) { }
            }
            return values;
        }
        
        function loadGACode() {
            if(getCookie( cookieName )) {
                if(getCookie( cookieName ).indexOf("c1:1") >= 0) {
                    var scripts = document.getElementsByTagName("script")[0];
                    var script = document.createElement("script");				
                    script.type = "text/javascript",
                    script.async = !0,
                    script.src = "/App_Themes/default/js/modules/ga-analytics.js",
                    scripts.parentNode.insertBefore(script, scripts);
                }
            }
        }
		
		if (!$consentPreferenceBox.length) return;
		$consentPreference.on("click", e => {
            $consentPreferenceBox.toggle();
			loadPreferencesValues();
		});
		
		$consentPreferenceBox.on( 'click', '#preferences_prompt_submit', e => {
			e.preventDefault();        
			updatedPreferencesValues();
            $consentPreferenceBox.hide();
            $consentBox.hide();
            loadGACode();
            //location.reload();
		} );

		$consentPreferenceBox.on( 'click', '#preferences_prompt_accept', e => {
            e.preventDefault(); 
			acceptAllPreferences();
		} );

		$consentPreferenceBox.on( 'click', '.close_btn_thick', e => {
			e.preventDefault();        
			//updatedPreferencesValues();
			$consentPreferenceBox.hide();
		} );
    });
}(jQuery));