(function ($) {
	$(function () {
		
		var $megadropdownbutton = $('.mega-dropdown-button');
		var $megadropdown = $('.mega-dropdown');
		$megadropdown.css( 'width', $('.mega-dropdown-column').length * 233 + 'px' );
		$megadropdownbutton.on({click: function() {
			$megadropdown.toggleClass( "active" );
			}
		});
		$( 'body' ).on( 'click', function( e ) {
			var $dropDown = $( '.mega-dropdown' );
			if( $dropDown.length > 0 ) {
				var $e = $( e.target );
				if( $e.closest( '.sublayout-activity-microsite-mega-dropdown' ).length === 0 ) {
					$dropDown.removeClass( 'active' );
				}
			}
		} );
	});
}(jQuery));