/* Copyright 2009-2014, Texas Instruments Inc. All rights reserved. */
$.fn.jeCarousel = function( options ) {
	options = $.extend( {
		delay: 5000,
		slideTime: 1000,
		resumeAfterClick: false,
		clickToStart: false,
		enableHover: false
	}, options );

	return this.each( function() {
		var $this = $(this);

		var $carousel =
			$.create( 'div' )
			.addClass( 'jecarousel' )
			.css( {
				position: 'relative',
				overflow: 'hidden'
			} );

		var $frames =
			$this
			.children( 'li' );

		var totalWidth = 0;
		$frames.each( function() {
			var $li = $(this).css( { position: 'absolute', left: totalWidth + 'px' } );
			totalWidth += $li.outerWidth();
		} );
		var avgWidth = totalWidth / $frames.length;

		$carousel =
			$this
			.wrapAll(
				$carousel
				.width( avgWidth )
				.height( $this.height() )
			)
			.parent();
			
		$this
		.css( { position: 'absolute', left: 0 } )
		.width( totalWidth );

		$frames
		.addClass( 'jecarousel-slide' )
		.each( function( index, e ) {
			$(e).addClass( 'jecarousel-slide-' + ( index + 1 ) )
			.data( 'slideIndex', index );
		} );

		var $buttons =
			$.create( 'ul' )
			.addClass( 'jecarousel-buttons' );

		var interval;

		var delay = options.delay;

		function nextFrame() {
			var $currentButton = $buttons.children( 'li.selected' );
			var $nextButton;
			if( $currentButton.is( ':last-child' ) )
				$nextButton = $buttons.children( 'li ').first();
			else
				$nextButton = $currentButton.next( 'li' );

			$nextButton.triggerHandler( 'select' );
		};

		var timeout;
		if( $frames.length > 1 ) {
			for( var i = 0; i < $frames.length; i++ ) {

				var li =
					$.create( 'li' )
					.addClass( 'jecarousel-button jecarousel-button-' + ( i + 1 ) )
					.append( $.create( 'span' ).text( i + 1 ) )
					.data( 'frame', $frames.eq( i ) )
					.data( 'buttonIndex', i )
					.bind( 'select', function() {
						var $t = $(this);

						if( $t.hasClass( 'selected' ) )
							return;

						$t.data( 'transitioning', true ).data( 'frame' );
						$buttons.children( 'li.selected' ).data( 'frame' );

						if( $buttons.data( 'transitioning' ) ) {
							clearTimeout( timeout );
							timeout = setTimeout( function() {
								$t.triggerHandler( 'select' );
							}, 10 );
							return;
						}
						$buttons.data( 'transitioning', true );

						$buttons.children( 'li' ).removeClass( 'selected' );
						$t.addClass( 'selected' );
						$this.animate( { left: ( -avgWidth * $t.data( 'buttonIndex' ) ) + 'px' }, options.slideTime, function() {
							$buttons.data( 'transitioning', false );
						} );
					} )
					.css( {
						cursor: 'pointer'
					} )
					.click( function( e ) {
						e.stopImmediatePropagation();
						clearInterval( interval );
						$(this).triggerHandler( 'select' );
						if( options.resumeAfterClick ) interval = setInterval( nextFrame, delay );
					} );

				if( options.enableHover ) {
					li.mouseover( function () {
						clearInterval( interval );
						$(this).triggerHandler( 'select' );
					} )
					.mouseout( function () {
						clearInterval( interval );
						interval = setInterval( nextFrame, delay );
					} );
				}

				$buttons.append( li );
			}

			$buttons.children( 'li' ).first().addClass( 'selected' );

			$carousel.after( $buttons );

			if( !options.clickToStart ) {
				clearInterval( interval );
				interval = setInterval( nextFrame, delay );
			}
		}
	} );
};