/*! Copyright 2009-2015, Texas Instruments Inc. All rights reserved. */

var Dialogs: IDialogs;

( () => {
	const $: JQueryStatic = window[ '$' ];
	(<any>($.param)).fragment.ajaxCrawlable( true );
	const $window = $( window );

	function getUrlPath() {
		const { protocol, hostname, port = '' } = location,
			origin = `${protocol}//${hostname}${port?`:${port}`:''}`;
		return location.href.slice( origin.length );
	}

	function requirePromise<T>( dep: string ) {
		return new Promise<T>( ( resolve, reject ) => {
			require( [ dep ], ( ...args ) => {
				if( args[ 0 ] ) resolve.apply( null, args );
				else reject( null );
			} );
		} );
	}

	function deprecated( name: string ) {
		requirePromise<typeof ConsoleWrapper>( 'console' ).then( console => console.deprecated( name ) );
	}

	function deprecatedGlobal( name: string, value: any ) {
		try {
			window[ name ] = value;
		} catch( ex ) {}

		try {
			Object.defineProperty( window, name, {
				get() {
					deprecated( `Global var:${name}` );
					return value;
				},
				configurable: false
			} );
		} catch( ex ) {}
	}

	deprecatedGlobal( '$window', $( window ) );
	$( () => {
		deprecatedGlobal( '$html', $( 'html' ) );
		deprecatedGlobal( '$body', $( 'body' ) );
	} );

	window[ 'Console' ] = ( () => {
		return [ 'Log', 'Info', 'Warn', 'Error', 'Assert', 'Clear', 'Dir', 'Profile', 'ProfileEnd' ].reduce<any>( ( con, commandName ) => {
			const camelCommandName = commandName.substring( 0, 1 ).toLowerCase() + commandName.substring( 1, commandName.length );

			con[ commandName ] = ( ...args ) => {
				deprecated( `Console.${commandName}` );
				return requirePromise<typeof ConsoleWrapper>( 'console' ).then( console => console[ camelCommandName ].apply( console, args ) );
			};

			return con;
		}, {} );
	} )();

// https://developers.google.com/analytics/devguides/collection/gajs/methods/
	( () => {
		const whenLoaded = requirePromise<typeof Analytics>( 'analytics' );

		window[ 'Analytics' ] = {
			setAccount(accountId: number) {
				deprecated( 'Analytics.SetAccount' );
			
				whenLoaded.then( analytics => { analytics.setAccount( accountId ); } );
			},
			setCustomVar(index: number, name: string, value: string, opt_scope?: string) {
				deprecated( 'Analytics.SetCustomVar' );

				whenLoaded.then( analytics => { analytics.setCustomVar( index, name, value, opt_scope ); } );
			},
			trackEvent(category: string, action: string, opt_label?: string, opt_value?: string, opt_noninteraction?: boolean) {
				deprecated( 'Analytics.TrackEvent' );

				whenLoaded.then( analytics => { analytics.trackEvent( category, action, opt_label, opt_value, opt_noninteraction ); } );
			},
			trackPageview(opt_pageURL?: string) {
				deprecated( 'Analytics.TrackPageview' );

				whenLoaded.then( analytics => { analytics.trackPageview( opt_pageURL ); } );
			},
			trackSocial(network: string, socialAction: string, opt_target?: string, opt_pagePath?: string) {
				deprecated( 'Analytics.TrackSocial' );

				whenLoaded.then( analytics => { analytics.trackSocial( network, socialAction, opt_target, opt_pagePath ); } );
			},
			trackTiming(category: string, variable: number, time: string, opt_label?: string) {
				deprecated( 'Analytics.TrackTiming' );

				whenLoaded.then( analytics => { analytics.trackTiming( category, variable, time, opt_label ); } );
			}
		};

		window[ 'Services' ] = {
			GetUrl( relativeUrl: string ) {
				deprecated( 'Services.GetUrl' );

				if( typeof relativeUrl !== 'string' || relativeUrl.charAt( 0 ) !== '/' ) throw new Error( `URL must be relative: ${relativeUrl}` );
				const baseUrl = ( $( 'html' ).data( 'services-baseurl' ) || '' ).replace( /\/+$/, '' );

				return `${baseUrl}${relativeUrl}`;
			},
			Activities: {
				Categories: {
					GetSubjects( siteId: string, language: string, params?: JQueryAjaxSettings ) {
						deprecated( 'Services.Activities.Categories.GetSubjects' );

						return requirePromise<typeof ActivityServices>( 'services/activities' ).then( activityServices => 
							( new activityServices.Categories ).getSubjects( siteId, language, params )
						);
					},
					GetSubjectAreas( subjectItemShortIds: Array<string>, siteId: string, language: string, params?: JQueryAjaxSettings ) {
						deprecated( 'Services.Activities.Categories.GetSubjectAreas' );

						return requirePromise<typeof ActivityServices>( 'services/activities' ).then( activityServices =>
							( new activityServices.Categories ).getSubjectAreas( subjectItemShortIds, siteId, language, params )
						);
					},
					GetTopics( subjectAreaItemShortIds: Array<string>, siteId: string, language: string, params?: JQueryAjaxSettings ) {
						deprecated( 'Services.Activities.Categories.GetTopics' );

						return requirePromise<typeof ActivityServices>( 'services/activities' ).then( activityServices =>
							( new activityServices.Categories ).getTopics( subjectAreaItemShortIds, siteId, language, params )
						);
					}
				}
			},
			Authentication: {
				Visitor: {
					SignIn( username: string, password: string, params?: JQueryAjaxSettings ) {
						deprecated( 'Services.Authentication.Visitor.SignIn' );

						return requirePromise<typeof AuthenticationServices>( 'services/authentication' ).then( authenticationServices =>
							( new authenticationServices.Visitor ).signIn( username, password, params )
						);
					},
					SignOut( params?: JQueryAjaxSettings ) {
						deprecated( 'Services.Authentication.Visitor.SignOut' );

						return requirePromise<typeof AuthenticationServices>( 'services/authentication' ).then( authenticationServices =>
							( new authenticationServices.Visitor ).signOut( params )
						);
					}
				}
			},
			Localization: {
				String: {
					Translate( itemPaths: Array<string>, language: string, params?: JQueryAjaxSettings ) {
						deprecated( 'Services.Localization.String.Translate' );

						return requirePromise<typeof LocalizationServices>( 'services/localization' ).then( localizationServices =>
							( new localizationServices.String ).translate( itemPaths, language, params )
						);
					}
				}
			},
			Downloads: {
				Licenses: {
					Fetch( downloadItemId: string, params?: JQueryAjaxSettings ) {
						deprecated( 'Services.Downloads.Licenses.Fetch' );

						return requirePromise<typeof DownloadServices>( 'services/downloads' ).then( downloadServices =>
							( new downloadServices.Licenses ).fetch( downloadItemId, params )
						);
					}
				}
			}
		};
	} )();


	( () => {
		function adapt( callback ) {
			return ( data: Localization.ITranslateResult ) => {
				if( typeof callback !== 'function' ) return;
				var o: Localization.ITranslateResultLegacy = undefined;
				if( data ) {
					o = {
						ItemPath: data.itemPath,
						Text: data.text,
						Found: data.found,
						DateFetched: data.dateFetched,
						Expires: data.expires
					};
				}
				callback( o );
			}
		}

		window[ 'Translate' ] = ( () => {
			var translate: any = ( itemPath: string, callback: ( itemResult: Localization.ITranslateResultLegacy ) => void ) => {
				deprecated( 'Translate' );

				requirePromise<typeof Localization>( 'localization' ).then( localization => localization.translate( itemPath ).then( adapt( callback ), adapt( callback ) ) );
			};

			translate.Defer = () => {
				deprecated( 'Translate.Defer' );

				requirePromise<typeof Localization>( 'localization' ).then( localization => localization.translate.defer() );
			};

			translate.Fetch = ( immediate ) => {
				deprecated( 'Translate.Fetch' );

				requirePromise<typeof Localization>( 'localization' ).then( localization => localization.translate.fetch( immediate ) );
			};

			return translate;
		} )();

		window[ 'TranslateAll' ] = ( itemPaths, callback ) => {
			deprecated( 'TranslateAll' );

			requirePromise<typeof Localization>( 'localization' ).then( localization => {
				localization.translate.defer();

				$.each( itemPaths, ( i, itemPath ) => {
					localization.translate( itemPath )
					.then( adapt( callback ), adapt( callback ) );
				} );
			
				localization.translate.fetch();
			} );
		};
	} )();

	Dialogs = window.Dialogs = ( () => {
		var dialogInitializers: IDictionary<Array<Function>> = {};

		function createDialog( key: string, dialogParams: IDialogParams ) {
			$.extend( dialogParams, { title: '', width: 300, modal: true, closable: true, draggable: true, resizable: false, fragment: '' }, $.extend( {}, dialogParams ) );

			return Dialogs[ key ] = ( () => {
				var dialogData: any = {},
					classes = [];

				if( dialogParams.modal ) classes.push( 'dialog-modal' );
				if( dialogParams.closable ) classes.push( 'dialog-closable' );
				if( dialogParams.draggable ) classes.push( 'dialog-draggable' );
				if( dialogParams.resizable ) classes.push( 'dialog-resizable' );
				if( dialogParams.fragment ) classes.push( `dialog-fragment-${dialogParams.fragment.replace(/\s/g,'-').toLowerCase()}` );
				classes.push( `dialog-key-${key.replace(/\s/g,'-').toLowerCase()}` );

				var dialog = dialogData.Dialog =
					$.create( 'div' )
					.dialog( {
						autoOpen: false,
						closeOnEscape: dialogParams.closable,
						width: dialogParams.width,
						modal: dialogParams.modal,
						title: dialogParams.title,
						draggable: dialogParams.draggable,
						resizable: dialogParams.resizable,
						dialogClass: classes.join( ' ' )
					} );

				dialogData.params = dialogParams;

				if( dialogParams.closable ) {
					requirePromise<typeof Localization>( 'localization' ).then( localization =>
						localization.translate( 'Dialogs/Close' )
						.then( itemResult => dialog.dialog( 'option', { closeText: itemResult.text } ) )
					);
				}

				dialog.on( 'dialogbeforeclose', () => {
					return dialogData.IsClosable();
				} );

				if( dialogParams.fragment ) {
					dialog.on( 'dialogclose', () => {
						var state = $.bbq.getState();
						if( state.dialog ) {
							try {
								delete state.dialog;
							} catch( ex ) {
								state.dialog = undefined;
							}
							$.bbq.pushState( state, 2 );
						}
					} );
				}

				dialog.on( 'style', () => {
					dialog.parent().find( '.ui-dialog-titlebar-close' ).toggle( dialogData.IsClosable() );
				} );

				dialogData.IsOpen = () => {
					return dialog && dialog.dialog( 'isOpen' );
				};

				dialogData.IsModal = () => {
					return dialogParams.modal;
				};

				dialogData.IsDraggable = () => {
					return dialogParams.draggable;
				};

				dialogData.IsResizable = () => {
					return dialogParams.resizable;
				};

				dialogData.IsClosable = () => {
					return dialogParams.closable;
				};

				dialogData.Close = () => {
					if( dialogData.IsOpen() ) {
						const closable = dialogData.IsClosable();
						dialogParams.closable = true;
						dialog.dialog( 'close' );
						dialogParams.closable = closable;
					}
				};

				let first = true;
				dialogData.Open = params => {
					if( dialogData.IsOpen() ) dialogData.Close();
					dialog.addClass( 'loading' );

					function position( xpos: string, ypos: string ) {
						const pos = [ xpos, ypos ].join( ' ' );
						dialog.dialog( 'option', { position: { my: pos, at: pos, of: window } } ).dialog( 'open' );
					}

					function finishOpen() {
						const p = params || {};
						dialog.data( 'params', p );
						dialog.triggerHandler( 'setParams', [ p ] );
						dialog.triggerHandler( 'style' );
						let [ xpos, ypos ] = [ 'center', 'center' ];

						dialog.removeClass( 'loading' );
						position( xpos, ypos );
						dialog.dialog( 'open' );
						const pos = dialog.parent().position();
						if( pos.left < 0 ) xpos = 'left';
						if( pos.top < 0 ) ypos = 'top';
						position( xpos, ypos );

						if( dialogParams.fragment ) {
							$.bbq.pushState( { dialog: dialogParams.fragment }, 0 );
						}
					};
					if( first ) {
						dialog.triggerHandler( 'init', [ { callback: finishOpen } ] );
						first = false;
					} else {
						finishOpen();
					}
				};

				let one: ( e: JQuery.Event, params?: any ) => void;
				if( dialogParams.url ) {
					one = ( ( e, params ) => {
						requirePromise<typeof Ajax>( 'ajax' )
						.then( ajax =>
							( new ajax.AjaxContext ).load( dialog, (<any>($.param)).querystring( dialogParams.url, { ref: getUrlPath() } ) )
							.then( () => { setTimeout( () => { if( params.callback ) params.callback(); }, 0 ); } )
						);
						dialog.off( 'init', one );
					} );
				} else {
					one = ( ( e, params ) => {
						if( params.callback ) params.callback();
						dialog.off( 'init', one );
					} );
				}

				dialog.on( 'init', one );
				return dialogData;
			} )();
		}

		return <IDialogs>{
			Init( key: string, callback: ( dialogData: IDialog ) => void ) {
				if( typeof Dialogs.SetUp === 'function' ) {
					const initializers = dialogInitializers[ key ];
					if( initializers ) initializers.push( callback );
					else dialogInitializers[ key ] = [ callback ];
				} else {
					callback( Dialogs[ key ] || ( Dialogs[ key ] = createDialog( key, {} ) ) );
				}
			},
			SetUp( json: IDictionary<any> ) {
				Dialogs.SetUp = undefined;
				delete Dialogs.SetUp;

				const dialogDatas = [];

				for( let i in json ) if( json.hasOwnProperty( i ) ) {
					const dialogData = createDialog( i, json[ i ] );
					dialogDatas.push( dialogData );
					const initializers = dialogInitializers[ i ];
					if( initializers ) for( let initializer of initializers ) {
						initializer( dialogData );
					}
				}

				window.onbeforeprint = () => {
					for( let i in dialogDatas ) if( dialogDatas.hasOwnProperty( i ) ) {
						const dialogData = dialogDatas[ i ];
						if( dialogData.IsOpen() && dialogData.IsModal() ) {
							$( '.layout-wrapper' ).hide();
							return;
						}
					}
				};

				window.onafterprint = () => {
					for( let i in dialogDatas ) if( dialogDatas.hasOwnProperty( i ) ) {
						const dialogData = dialogDatas[ i ];
						if( dialogData.IsOpen() && dialogData.IsModal() ) {
							$( '.layout-wrapper' ).show();
							return;
						}
					}
				};

				$window.trigger( 'hashchange.dialog' );
			}
		};
	} )();

	$( () => {
		const $html = $( 'html' ),
			lang = $html.attr( 'lang' );
		$.datepicker.setDefaults( $.datepicker.regional[ lang === 'en' ? '' : lang ] );

		( ( () => {
			$window.on( 'hashchange.dialog', () => {
				const d = $.bbq.getState().dialog;

				for( let dd in Dialogs ) if( Dialogs.hasOwnProperty( dd ) ) {
					const dialogData = Dialogs[ dd ],
						params = dialogData.params;
					if( dialogData && params && params.fragment ) {
						if( params.fragment === d ) {
							if( dialogData.IsOpen && !dialogData.IsOpen() ) {
								dialogData.Open();
							}
						} else {
							if( dialogData.IsOpen && dialogData.IsOpen() ) {
								dialogData.Close();
							}
						}
					}
				}
			} );

			Dialogs.Init( 'Loading', dialogData => {
				$.create( 'div' ).addClass( 'loading' ).css( { height: '100px', width: '100%' } ).appendTo( dialogData.Dialog );
			} );

			Dialogs.Init( 'Notice', dialogData => {
				const dialog = dialogData.Dialog,
					$content =
						$.create( 'div' )
							.attr( { id: 'dialog-notice' } )
							.appendTo( dialog );

				dialog.on( 'setParams', ( e, p ) => {
					const params = $.extend( {}, {
						message: '',
						buttons: {},
						icon: ''
					} , p );

					dialog.dialog( 'option', {
						buttons: params.buttons
					} );

					$content.text( params.message );

					$content.prevAll().remove();

					if( params.icon ) {
						$( '<span/>' )
							.addClass( 'ui-icon ui-icon-' + params.icon )
							.insertBefore( $content ); 
					}
				} );
			} );

			Dialogs.Init( 'SignIn', dialogData => {
				const $html = $( 'html' ),
					$body = $( 'body' );
				let visitorSignedIn = $html.hasClass( 'visitor-signed-in' );
				var $selecteddownloadLink;
				function hookup( selector: string, optional: boolean, callback?: ( $t: JQuery ) => void ) {
					$( 'body' ).on( 'click.signin', selector, e => {
						var $t = $selecteddownloadLink = $(e.target);

						if( visitorSignedIn ) return;
						if( optional && ( $.cookie( 'axdc-guest' ) === '1' ) ) return;
						e.preventDefault();
						e.stopImmediatePropagation();

						dialogData.Open( { optional, callback: () => {
							visitorSignedIn = true;
							if( callback ) { callback( $t ); }
						} } );
					} );
				}

				hookup( 'a.action-signin', false, () => { location.reload(); } );

				$body.on( 'click', '.jump-list li:first a', ( ev ) => {
					var $downloadLink = $( 'a.download-file[data-fileid]' );
					if( $downloadLink.length > 0 ) {
						ev.preventDefault();
						const url = (<any>($.param)).fragment( (<any>($.param)).querystring( location.href, { download: $selecteddownloadLink.data( 'fileid' ) } ), { download: 'start' } ),
							accurl = $( this ).attr( 'href' ),
							updatedURL = (<any>($.param)).querystring( accurl, { siteref: url } );
						location.href = decodeURI( updatedURL );
					}
				} );

				$body.on( 'click.signout', 'a.action-signout', ( e ) => {
					if( !visitorSignedIn ) return;
					e.preventDefault();

					requirePromise<typeof AuthenticationServices>( 'services/authentication' ).then( authenticationServices => {
						( new authenticationServices.Visitor ).signOut().then( () => {
							visitorSignedIn = false;
							location.href = $( '#site-logo' ).attr( 'href' ) || '/';
						} );
					} );
				} );

				function fn( $t: JQuery ) {
					requirePromise<typeof EventSimulator>( 'event-simulator' ).then( ev => { ev.click( $t ); } );
				};

				hookup( 'a.download-file[data-fileid]', true, fn );
				hookup( 'a.signin-optional', true, fn );
				hookup( 'a.signin-mandatory', false, fn );
				hookup( 'input[name=my]', false );
			} );
		} )() );

		( () => {
			function whenLoaded( fn ) {
				requirePromise<typeof Lightboxes>( 'lightboxes' ).then( fn, () => {} );
			}

			window[ 'Lightbox' ] = {
				Close() {
					deprecated( 'Lightbox.Close' );

					whenLoaded( lightboxes => { lightboxes.close(); } );
				},
				Open( child: HTMLElement|JQuery, hasClose?: boolean ) {
					deprecated( 'Lightbox.Open' );

					whenLoaded( lightboxes => { lightboxes.open( $( child ).get( 0 ), hasClose ); } );
				},
				Resize() {
					deprecated( 'Lightbox.Resize' );

					whenLoaded( lightboxes => { lightboxes.resize(); } );
				},
				SetUp( data ){
					whenLoaded( lightboxes => {
						delete window[ 'Lightbox' ].SetUp;
						lightboxes.initialize( data )
						.then( () => { lightboxes.checkFragment(); } );
					} );
				}
			};

			const lightboxesLoaded = $.Deferred();

			window[ 'closeLightbox' ] = () => { lightboxesLoaded.then( ( lightboxes: typeof Lightboxes ) => { lightboxes.close(); } ) };

			whenLoaded( lightboxes => {
				lightboxesLoaded.resolve( lightboxes );

				$( window ).resize( () => lightboxes.resize() );

				$(document).keyup( e => {
					if( e.which === 27 ) { // escape
						e.preventDefault();

						lightboxes.close();
					}
				} );

				$window.on( 'hashchange.checklightbox', () => { lightboxes.checkFragment(); } );
			} );
		} )();

		/*
		*	Browser Sniffer
		*	 -sometimes you just need to do it.
		*	 -Creates classes on the html tag to identify IE versions
		*/
		( () => {
			var navigator = window.navigator,
				userAgent = ( !!navigator && navigator.userAgent ) || '',
				appVersion = ( !!navigator && navigator.appVersion ) || '',
				isIe = /\b(?:msie|trident)\b/i.test( userAgent ) && !/\bopera\b/i.test( userAgent ),
				ieVersion = isIe && parseInt( ( /\b(?:msie|rv\:)\s*(\d+)/i.exec( appVersion || userAgent ) || [] )[ 1 ], 10 ),
				compatibilityMode = isIe && ieVersion <= 7 && document.compatMode === 'BackCompat';
			if( isIe ) {
				$html.addClass('ie').addClass( `ie${ieVersion}` );
				if( compatibilityMode || ieVersion <= 6 ) {
					$html.addClass( 'ie-quirks' );
				}
				if( compatibilityMode || ieVersion <= 8 ){
					$html.addClass( 'oldie' );
				}
			} else {
				$html.addClass( 'no-ie' );
			}
		} )();

		/*
		*	Shared Code
		*	 -This code area is specifically for any code that needs to run on every page.
		*/
		
		// If the page is in print mode, then pop up the Print window
		if( $('html').is('.device-print') ) {
			window.print();
		}

		// check for Flash Support
		if( !$.flash.hasVersion( '11.2.0' ) ) {
			$html.addClass( 'no-flash' );
		}	

		// build in the ability to hard set the Flash Support Flag
		( () => {
			var idx = $.bbq.getState(),
				useFlash = ( idx.flash !== 'false' );

			if( !useFlash ) {
				$html.addClass( 'no-flash' );
			}

			$window.on( 'hashchange', () => {
				idx = $.bbq.getState();
				if( useFlash !== ( idx.flash !== 'false' ) ){
					location.reload();
				}
			} );
		} )();
		
		// binds .bbq items to urls fragments. If the fragment matches then the item
		// is tagged with the 'selected' class.
		$window.on( 'hashchange', () => {
			$( '.bbq' ).each( ( index, object ) => {
				var that = $(object), url = $.bbq.getState( that.attr( 'id' ) ) || '';
	
				that.find( '.selected' ).removeClass( 'selected' );

				if( url ) {
					that.find( 'li a' ).each( function() {
						const $object = $(this);
						if( $object.attr( 'href' ) === ( '#' + url ) ) {
							$object.parent().addClass( 'selected' );

							return false;
						}
						return true;
					} );
				} else {
					that.find( 'li:first-child' ).addClass( 'selected' );
				}
			} );
		} );

		$( '.jump-ddl' ).on( 'change', 'select', e => {
			var v = $(e.currentTarget).find( 'option:selected' );
			if(v.val() && v.data( 'target' )){
				v.off( 'popup' );

				v.popupWindow({
					centerBrowser: 0,
					centerScreen: 0,
					height: $(window).height(),
					left: 0,
					location: 1,
					menubar: 1, 
					resizable: 1,
					scrollbars: 1,
					status: 1,
					width: $(window).width(), 
					windowName: v.data('target'), 
					windowURL: v.val(), 
					top: 0,
					toolbar: 1, 
					onClick: false 
				}).trigger('popup');
			}
			else if( v.val() ){ location.href = v.val();}
		} );

		$( '#header-site-mobile' ).each( ( i, header ) => {
			$(header).on( 'click', '.hamburger', e => {
				e.preventDefault();
				$(e.currentTarget).toggleClass( 'expanded collapsed' );
			} );
		} );

		// sets a class (selected) on items that are collapsible so that they maybe be dealt with.
		$( 'ul.collapsible' )
		.click( function( e ) {
				const $target = $(e.target);
				const $this = $(this);
				if( $target.is( 'li nav li a' ) ) {
				$this.find( 'li nav li.selected' ).removeClass( 'selected' );
			}

			$target.parentsUntil( '.collapsible' ).first().toggleClass( 'selected' );
		} );

		$( '*[data-transition="slideshow"]' ).each( ( index, object ) => {
			var $slideshow = $(object).addClass( 'etslideshow' );

			var $wrapper = $.create( 'div' ).addClass( 'etslideshow-wrapper' ).css( { width: 0, height: 0 } );

			var transitionDelay = parseInt( $slideshow.attr( 'data-transition-delay' ), 10 );
			if( isNaN( transitionDelay ) || !isFinite( transitionDelay ) || transitionDelay < 100 ) transitionDelay = 12000;

			var transitionDuration = parseInt( $slideshow.attr( 'data-transition-duration' ), 10 );
			if( isNaN( transitionDuration ) || !isFinite( transitionDuration ) || transitionDuration < 100 ) transitionDuration = 1000;

			var slideshowWidth = 0, slideshowHeight = 0;

			var $slides =
				$slideshow
				.children( ':not([data-transition-exclude="data-transition-exclude"])' )
				.addClass( 'etslideshow-slide' )
				.each( ( index, object ) => {
					var $slide = $(object);
					$slide.addClass( 'etslideshow-slide-' + ( index + 1 ) ).data( 'etslideshow-slideindex', index );
					var slideWidth = $slide.outerWidth( true );
					if( slideshowWidth < slideWidth ) slideshowWidth = slideWidth;
					var slideHeight = $slide.outerHeight( true );
					if( slideshowHeight < slideHeight ) slideshowHeight = slideHeight;
				} );

			$wrapper.width( slideshowWidth );
			$wrapper.height( slideshowHeight );

			$slides.each( ( index, object ) => {
				var $slide = $(object);

				$slide.wrapInner( '<div class="etslideshow-inner-1"><div class="etslideshow-inner-2"/></div>' );

				var $wrapper1 = $slide.children( '.etslideshow-inner-1' );

				$wrapper1.width( slideshowWidth );
				$wrapper1.height( slideshowHeight );

				$slide.css( { margin: 0, padding: 0 } );
			} );

			$slideshow.wrapAll( $wrapper );

			var $buttons = $.create( 'ul' ).addClass( 'etslideshow-buttons' ).data( 'etslideshow-advance', !$html.hasClass( 'pagemode-pageeditorediting' ) );

			var nextFrame = () => {
				var $currentButton = $buttons.children( '.etslideshow-selected' );
				var $nextButton = $currentButton.is( ':last-child' ) ? $buttons.children().first() : $currentButton.next();
				$nextButton.triggerHandler( 'select.etslideshow' );
			};

			var timeout;
			var slideCount = $slides.length;
			if( slideCount > 1 ) {
				for( let i = 0; i < slideCount; i++ ) {
					const displayIndex = '' + ( i + 1 );
					$buttons.append(
						$.create( 'li' )
						.addClass( 'etslideshow-button etslideshow-button-' + displayIndex )
						.append( $.create( 'span' ).text( displayIndex ) )
						.data( 'etslideshow-slide', $slides.eq( i ) )
						.on( 'select.etslideshow', e => {
							var $button = $(e.currentTarget);
							if( $button.hasClass( 'etslideshow-selected' ) ) return;

							var $newSlide = $button.data( 'etslideshow-slide' );
							var $oldSlide = $buttons.children( '.etslideshow-selected' ).data( 'etslideshow-slide' );

							$slides.stop( true, true );
							$buttons.children().removeClass( 'etslideshow-selected' );
							$button.addClass( 'etslideshow-selected' );

							$oldSlide.css( { 'z-index': 3 } );
							$newSlide.css( { 'z-index': 2 } );
							$oldSlide.fadeTo( 0, 1 );
							$newSlide.fadeTo( 0, 1 );
							
							$oldSlide.fadeTo( transitionDuration, 0, () => {
								$newSlide.css( { 'z-index': 3 } );
								$oldSlide.css( { 'z-index': 2 } );
							} );

							if( $buttons.data( 'etslideshow-advance' ) ) timeout = setTimeout( nextFrame, transitionDelay );
						} )
						.click( function( e ) {
							$buttons.data( 'etslideshow-advance', false );
							if( timeout ) clearTimeout( timeout );
							timeout = null;
							e.stopImmediatePropagation();
							$(this).triggerHandler( 'select.etslideshow' );
						} )
					);
				}

				$slides.css( { 'z-index': 3 } ).slice( 1 ).css( { 'z-index': 2 } ).fadeTo( 0, 0 );
				$buttons.children().first().addClass( 'etslideshow-selected' );

				$slideshow.after( $buttons );
				if( $buttons.data( 'etslideshow-advance' ) ) timeout = setTimeout( nextFrame, transitionDelay );
			}
		} );

		$( '*[data-transition="carousel"]' ).each( ( index, object ) => {
			var $carousel = $(object).addClass( 'etcarousel' );

			var $wrapper = $.create( 'div' ).addClass( 'etcarousel-wrapper' ).css( { width: 0, height: 0 } );

			var transitionDelay = parseInt( $carousel.attr( 'data-transition-delay' ), 10 );
			if( !isFinite( transitionDelay ) || transitionDelay < 100 ) transitionDelay = 12000;

			var transitionDuration = parseInt( $carousel.attr( 'data-transition-duration' ), 10 );
			if( !isFinite( transitionDuration ) || transitionDuration < 100 ) transitionDuration = 1000;

			var carouselWidth = 0, carouselHeight = 0;

			var $slides =
				$carousel
				.children( ':not([data-transition-exclude="data-transition-exclude"])' )
				.addClass( 'etcarousel-slide' )
				.each( ( index, object ) => {
					var $slide = $(object);
					$slide.addClass( 'etcarousel-slide-' + ( index + 1 ) ).data( 'etcarousel-slideindex', index );
					var slideWidth = $slide.outerWidth( true );
					if( carouselWidth < slideWidth ) carouselWidth = slideWidth;
					var slideHeight = $slide.outerHeight( true );
					if( carouselHeight < slideHeight ) carouselHeight = slideHeight;
				} );

			$wrapper.width( carouselWidth );
			$wrapper.height( carouselHeight );

			$slides.each( ( index, object ) => {
				var $slide = $(object).css( { position: 'absolute', left: ( carouselWidth * index ) + 'px' } );

				$slide.wrapInner( '<div class="etcarousel-inner-1"><div class="etcarousel-inner-2"/></div>' );

				var $wrapper1 = $slide.children( '.etcarousel-inner-1' );

				$wrapper1.width( carouselWidth );
				$wrapper1.height( carouselHeight );

				$slide.css( { margin: 0, padding: 0 } );
			} );

			$carousel.wrapAll( $wrapper );

			var $buttons = $.create( 'ul' ).addClass( 'etcarousel-buttons' ).data( 'etcarousel-advance', !$html.hasClass( 'pagemode-pageeditorediting' ) );

			var nextFrame = () => {
				var $currentButton = $buttons.children( '.etcarousel-selected' );
				var $nextButton = $currentButton.is( ':last-child' ) ? $buttons.children().first() : $currentButton.next();
				$nextButton.triggerHandler( 'select.etcarousel' );
			};

			var timeout;
			var slideCount = $slides.length;
			$carousel.width( slideCount * carouselWidth );
			$carousel.css( { position: 'absolute', left: 0 } );
			if( slideCount > 1 ) {
				for( let i = 0; i < slideCount; i++ ) {
					const displayIndex = '' + ( i + 1 );
					$buttons.append(
						$.create( 'li' )
						.addClass( `etcarousel-button etcarousel-button-${displayIndex}` )
						.append( $.create( 'span' ).text( displayIndex ) )
						.data( 'etcarousel-slide', $slides.eq( i ) )
						.data( 'etcarousel-buttonindex', i )
						.on( 'select.etcarousel', e => {
							var $button = $(e.currentTarget);
							if( $button.hasClass( 'etcarousel-selected' ) ) return;

							var buttonIndex = parseInt( <any>$button.data( 'etcarousel-buttonindex' ), 10 );
							if( isNaN( buttonIndex ) || !isFinite( buttonIndex ) ) return;

							$carousel.stop( true, true );
							$buttons.children().removeClass( 'etcarousel-selected' );
							$button.addClass( 'etcarousel-selected' );
							
							$carousel.animate( { left: ( -carouselWidth * buttonIndex ) + 'px' }, transitionDuration );

							if( $buttons.data( 'etcarousel-advance' ) ) timeout = setTimeout( nextFrame, transitionDelay );
						} )
						.click( function( e ) {
							$buttons.data( 'etcarousel-advance', false );
							if( timeout ) clearTimeout( timeout );
							timeout = null;
							e.stopImmediatePropagation();
							$(this).triggerHandler( 'select.etcarousel' );
						} )
					);
				}

				$buttons.children().first().addClass( 'etcarousel-selected' );

				$carousel.after( $buttons );
				if( $buttons.data( 'etcarousel-advance' ) ) timeout = setTimeout( nextFrame, transitionDelay );
			}
		} );

		$.extend($.ajaxSettings, { traditional: true });

		/*Description : Calculating Ebook pages content height */
		$( () => {
			var maxAsideHeight = 0;
			$( '#digitalBook > aside' ).each( function() {
				maxAsideHeight = $(this).outerHeight() > maxAsideHeight ? $(this).outerHeight() : maxAsideHeight;
			} );
			$( '#digitalBook' ).height( maxAsideHeight );
		} );

		/*Description : Student Zone script*/
	
		requirePromise<typeof Analytics>( 'analytics'  ).then( analytics => {
		/*Tracking External Urls*/
				var domainList = ['']; //pass domains here e.g. "twitter.com", "www.youtube.com", "www.timathforward.com", "www.ti.com"
			$( 'a[href^="http"]:not([href*="' + window.location.host + '"])' ).each( ( i, e ) => {
				var url = $(e).attr('href');
				var hostName = $(e).prop( 'hostname' );
				if( $.inArray(hostName,domainList) === -1 ){
					$(e).on( 'click', () => {
						analytics.trackEvent( 'External Links', url );
					} );
				}
			} );
		} );
	} );
} )();