//Location Cookie start
(function ($) {
    $(function () {
        var cookieName = "CONSENTMGR",
            cookieExpire = 60,
            $consentBox = $("#tiGDPRecModal");

        function setPreferencesValues() {
            var cookieData = { ts: new Date().getTime(), consent: true, c1: 1, c2: 1, c3: 1, c4: 1, c5: 1, c6: 1, c7: 1, c8: 1, c9: 1, c10: 1, c11: 1, c12: 1, c13: 1, c14: 1, c15: 1 };
            setCookie(cookieData);
        }

        function getDomainName(hostName) {
            return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1));
        }

        function getCookie(cname) {
            var name = cname + "=";
            var ca = document.cookie.split(";");
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }

        function setCookie(cookieData) {            
            var mo2Val = [];
            for(var i in cookieData) {
                if( cookieData.hasOwnProperty( i ) ) {
                    mo2Val.push( i + ":" + cookieData[i] );
                }
            }
            var expires=new Date();
            expires.setDate(expires.getDate() + cookieExpire);
            var cookieValue = (mo2Val.join("|"));
            var cookieString = "";
            cookieString = cookieName + "=" + encodeURI(cookieValue) + "; path=/; expires=" + expires.toUTCString() + "; domain=" + getDomainName(window.location.hostname);
            document.cookie = cookieString;
        }
        
        function refreshCookie() {
            setCookie(getGDPRCookieValues());
        }

        function getGDPRCookieValues() {
            var values = {};
            try {
                var cookieData = getCookie(cookieName);
                if (cookieData) {
                    var i, optOut, optOutData = decodeURI(cookieData).split("|");
                    for (i = 0; i < optOutData.length; i++) {
                        optOut = optOutData[i].split(":");
                        values[optOut[0]] = optOut[1];
                    }
                }
            } catch (e) { }
            return values;
        }
        
        function loadGACode() {
            if(getCookie( cookieName )) {
                if(getCookie( cookieName ).indexOf("c1:1") >= 0) {
                    var scripts = document.getElementsByTagName("script")[0];
                    var script = document.createElement("script");				
                    script.type = "text/javascript",
                    script.async = !0,
                    script.src = "/App_Themes/default/js/modules/ga-analytics.js",
                    scripts.parentNode.insertBefore(script, scripts);
                    scripts = document.getElementsByTagName("script")[0];
                    script = document.createElement("script");				
                    script.type = "text/javascript",
                    script.async = !0,
                    script.src = "/App_Themes/default/js/modules/ga4-analytics.js",
                    scripts.parentNode.insertBefore(script, scripts);
                    
                }
            }
        }

        if (document.cookie.indexOf(cookieName) > -1) {
            refreshCookie();
        }
        if (!$consentBox.length) return;
        $consentBox.toggle(document.cookie.indexOf(cookieName) === -1);
        $consentBox.on("click", "#consent_prompt_submit", e => {
            e.preventDefault();
            setPreferencesValues();
            $consentBox.hide();
            loadGACode();
            //location.reload();
        });
        $consentBox.on("click", "#privacy_link_optin", e => {
            //e.preventDefault();        
            //setPreferencesValues();
            //$cookieBox.hide();
        });
        $consentBox.on( 'click', '#consent_pref_center', e => {
            //e.preventDefault();
            //setPreferencesValues();
            //$consentBox.hide();
        } );
    });
}(jQuery));