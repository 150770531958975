/* Copyright 2009-2014, Texas Instruments Inc. All rights reserved. */
$.fn.jeSlideshow = function (options) {
	var defaults = {
		delay: 5000,
		fadeTime: 1000,
		clickToStart: false,
		enableHover: false
	};

	options = $.extend(defaults, options);

	return this.each(function () {
		var $this = $(this);

		var $slideshow = $this.addClass('jeslideshow');
		var $frames =
			$slideshow
			.children('li')
			.addClass('jeslideshow-slide')
			.each(function (index, e) {
				$(e).addClass('jeslideshow-slide-' + (index + 1))
				.data('slideIndex', index);
			});

		var $buttons =
			$.create('ul')
			.addClass('jeslideshow-buttons');

		var interval;

		var delay = options.delay;
		var fadeTime = options.fadeTime;

		var nextFrame = function () {
			var $currentButton = $buttons.children('li.selected');
			var $nextButton;
			if ($currentButton.is(':last-child'))
				$nextButton = $buttons.children('li ').first();
			else
				$nextButton = $currentButton.next('li');

			$nextButton.triggerHandler('select');
		};

		var timeout;
		if ($frames.length > 1) {
			for (var i = 0; i < $frames.length; i++) {

				var li =
					$.create('li')
					.addClass('jeslideshow-button jeslideshow-button-' + (i + 1))
					.append($.create('span').text(i + 1))
					.data('frame', $frames.eq(i))
					.data('buttonIndex', i)
					.bind('select', function () {
						var $t = $(this);
						if ($t.hasClass('selected'))
							return;

						var $thisFrame = $t.data('transitioning', true).data('frame');
						var $selectedFrame = $buttons.children('li.selected').data('frame');

						if ($buttons.data('transitioning')) {
							clearTimeout(timeout);
							timeout = setTimeout(function () {
								$t.triggerHandler('select');
							}, 10);
							return;
						}
						$buttons.data('transitioning', true);

						$buttons.children('li').removeClass('selected');
						$t.addClass('selected');
						$selectedFrame.css({ display: 'block', 'z-index': 3 });
						$thisFrame.css({ display: 'block', 'z-index': 2 });

						$selectedFrame.fadeOut(fadeTime, function () {
							$thisFrame.css({ 'z-index': 3 });
							$buttons.data('transitioning', false);
						});
					})
					.css({
						cursor: 'pointer'
					})
					.click(function (e) {
						e.stopImmediatePropagation();
						clearInterval(interval);
						$(this).triggerHandler('select');
						interval = setInterval(nextFrame, delay);
					});
				if (options.enableHover) {
					li.mouseover(function () {
						clearInterval(interval);
						$(this).triggerHandler('select');
					})
					.mouseout(function () {
						clearInterval(interval);
						interval = setInterval(nextFrame, delay);
					});
				}

				$buttons.append(li);
			}

			$frames.css({ 'z-index': 2 }).slice(1).hide();
			$buttons.children('li').first().addClass('selected');

			$slideshow.after($buttons);

			if (!options.clickToStart) {
				clearInterval(interval);
				interval = setInterval(nextFrame, delay);
			}
		}
	});
	};